<template>
  <b-card-code :title="title">

    <b-row class="m-0">

      <b-form-group
          label="Види палива"
          label-for="fuels"
          class="mr-2"
      >
        <v-select
            v-model="checkedFuels"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="fuels"
            required
            :reduce="val => val.value"
            @input="changeFilters"
            class="maps-filter-select"
        />
      </b-form-group>

      <b-form-group
          label="АЗС"
          label-for="azs"
      >
        <v-select
            v-model="checkedAzs"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="azs"
            required
            :reduce="val => val.value"
            @input="changeFilters"
            class="maps-filter-select"
        />
      </b-form-group>

    </b-row>

    <div class="map" ref="map"></div>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import axiosIns from "@/libs/axios";
import vSelect from 'vue-select'
import {BFormGroup, BRow,} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    vSelect,
    BFormGroup,
    BRow,
  },
  props: ['locations', 'companies', 'title'],
  data() {
    return {
      fuels: [],
      checkedFuels: [],
      azs: [],
      checkedAzs: [],
      gmarkers: [],
    }
  },
  methods: {
    async getFuels() {
      return axiosIns
          .get('/common/extra/fuels/internal')
          .then(response => {
            return response.data;
          }).catch(() => {
            return false;
          });
    },
    changeFilters() {
      this.gmarkers.forEach(marker => {

        const isFilteredFuels = marker.fuels.filter(value => this.checkedFuels.includes(value)).length
        const isFilteredAzs = this.checkedAzs.includes(marker.title)

        let isVisible = false
        if (this.checkedFuels.length && !this.checkedAzs.length) {
          isVisible = isFilteredFuels
        } else if (!this.checkedFuels.length && this.checkedAzs.length) {
          isVisible = isFilteredAzs
        } else if (!this.checkedFuels.length && !this.checkedAzs.length) {
          isVisible = true
        } else {
          isVisible = isFilteredFuels && isFilteredAzs
        }

        if (isVisible) {
          marker.setVisible(true);
        } else {
          marker.setVisible(false);
        }
      });
    },
    initGmaps() {
      const map = new google.maps.Map(this.$refs.map, {
        zoom: 6,
        center: {
          lat: 49.237713,
          lng: 31.436018,
        },
        draggable: true,
        scrollwheel: true,
        disableDefaultUI: false,
        styles: [
          {
            "featureType": "administrative",
            "elementType": "all",
            "stylers": [
              {
                "saturation": "-100"
              }
            ]
          },
          {
            "featureType": "administrative.province",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "lightness": 65
              },
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "lightness": "50"
              },
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
              {
                "saturation": "-100"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [
              {
                "lightness": "30"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [
              {
                "lightness": "40"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "visibility": "simplified"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "hue": "#ffff00"
              },
              {
                "lightness": -25
              },
              {
                "saturation": -97
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels",
            "stylers": [
              {
                "lightness": -25
              },
              {
                "saturation": -100
              }
            ]
          }
        ]
      });

      if (this.locations) {
        Object.entries(this.locations).forEach(locationItem => {
          const [location, list] = locationItem;
          const company = this.companies.filter(company => company.title.toLowerCase() == location.toLowerCase())[0]

          const icon = company.image
          const title = company.title

          list.forEach(azs => {
            this.createMarker(map, azs.location.lat, azs.location.lng, icon, title, azs.address, azs.fuels)
          })
        });
      } else {
        console.error('No data LOCATIONS!');
      }
    },

    createMarker(map, lat, lng, icon, title, address, fuels) {

      var infoWindow = new google.maps.InfoWindow({
        disableAutoPan: false,
        maxWidth: 450,
        zIndex: 1
      });

      const iconMarker = {
        url: icon,
        scaledSize: new google.maps.Size(30, 30),
      };

      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map: map,
        animation: google.maps.Animation.DROP,
        clickable: true,
        title: title,
        zIndex: 1,
        icon: iconMarker,
        fuels: fuels
      });

      this.gmarkers.push(marker)

      let content = '<h4>' + title + '</h4>' +
          '<p>' + address + '</p>' +
          '<h5>Доступне пальне</h5>' +
          '<ul>'

      fuels.forEach(fuel => {
        content += '<li>' + fuel + '</li>';
      })
      content += '</ul>' +
          '<a target="_blank" href="https://www.google.com.ua/maps/place/' + lat + ',' + lng + '">Подивитись в Google картах</a>'

      google.maps.event.addListener(marker, 'click', function (e) {
        infoWindow.close();
        infoWindow.setContent(content)
        infoWindow.open(map, marker)
      });
    }
  },
  async created() {
    let fuels = await axiosIns
        .get('/common/extra/fuels/internal')
        .then(response => {
          return response.data;
        }).catch((e) => {
          console.error(e);
          return false;
        })

    fuels.items.forEach(fuel => {
      fuel.label = fuel.title
      delete fuel.title
    });
    this.fuels = fuels.items

    setTimeout(() => {
      this.initGmaps();
      if (this.companies) {
        this.companies.forEach(company => {
          this.azs.push({
            label: company.title,
            value: company.title,
          })
        });
      }
    }, 3000);
  }
}
</script>

<style lang="scss">

.map {
  height: 500px;
  width: 100%;
}

.code-toggler {
  display: none;
}

.gm-style .gm-style-iw-d,
.gm-style .gm-style-iw-d h4,
.gm-style .gm-style-iw-d h5 {
  color: #000 !important;
}

.maps-filter-select {
  width: 250px;
}

</style>
