<template>
  <b-tabs content-class="mt-1">

    <!-- <b-tab title="Точки поповнення">
      <leaflet-basic :title="'Точки поповнення'" :locations="deposits.locations" :companies="deposits.companies"/>
    </b-tab> -->

    <b-tab
      title="Карта АЗС"
    >
      <leaflet-basic :title="'Карта АЗС'" :locations="fuels.locations" :companies="fuels.companies"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BAlert} from 'bootstrap-vue'
import { BRow, BCol } from 'bootstrap-vue'
import LeafletBasic from './LeafletBasic.vue'
import Maps from './maps';

export default {
  components: {
    BTabs,
    BTab,
    BAlert,
    BRow,
    BCol,
    LeafletBasic,
  },
  data() {
    return {
      deposits: [],
      fuels: [],
    }
  },
  methods: {
  },
  async created() {
    // this.deposits = await Maps.deposits();
    this.fuels = await Maps.fuels();
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>
