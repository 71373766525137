import axiosIns from "@/libs/axios";

class Maps {

    deposits() {
        const response = axiosIns
            .get('/business/maps/deposits')
            .then(response => {
                return response.data;
            }).catch(() => {
                return false;
            });

        return response
    }

    fuels() {
        const response = axiosIns.get('/business/maps/fuels')
            .then(response => {
                return response.data;
            }).catch(() => {
                return false;
            });

        return response
    }
}

export default new Maps();